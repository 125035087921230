  .drawer__inner {
    background: #444b5d url('../images/wave-drawer.svg') no-repeat bottom/100% auto;
  }
  
  .drawer__inner__mastodon {
    background: url('../../assets/images/mastodon-trans.svg') no-repeat 0 100%/contain;
  }
  
  .landing-page__call-to-action {
      background:  #1f232b url('../../assets/images/mastodon-trans.svg') no-repeat 0 100%/contain;
  }

  .drawer__inner__mastodon img, .landing-page__mascot img {
    display: none;
  }
